import './App.css';

function App() {
  return (
    <div className="App">
      <form method="POST" action="/fetch">
        <label>Enter the URL:</label>
        <input type="url" name="url" />
      </form>
    </div>
  );
}

export default App;
